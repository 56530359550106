const faqwa = {
  heading2: {
    color: "teal",
    subtitle: "Preguntas Frecuentes",
    title: "",
    description:
      "Si tienes alguna duda adicional, contáctanos directamente por WhatsApp. Estaremos encantados de resolver hasta la más mínima pregunta que tengas relacionada con nuestros servicios.",
    alignment: "center",
  },
  accordion: {
    color: "lightBlue",
    multiple: false,
    //defaultOpened: 0,
    items: [
      {
        title:
          "¿Hay algún cambio a mi número al migrarlo a WhatsApp Cloud API?",
        description:
          "Sí. Tu número pasa a ser un número de WhatsApp Cloud API. Esto significa que ya no puede ser usado con la aplicación tradicional de WhatsApp o WhatsApp Business. Sin embargo, ningún momento deja de ser de tu propiedad. Tanto tu número como tus mensajes te pertenecen en todo momento.",
      },
      {
        title: "¿Puedo usar mi propio número de teléfono?",
        description:
          "Sí. Puedes migrar el número que usas actualmente en la aplicación de WhatsApp, a la API de WhatsApp. Ten presente que no podrás volver a usar ese número en la aplicación móvil de WhatsApp. También es posible añadir una línea separada de teléfono, para usar exclusivamente para WhatsApp Cloud API y tus operaciones de Marketing.",
      },
      {
        title: "¿Hay algún cobro extra si envío muchos mensajes?",
        description:
          "Puedes enviar tantos mensajes como tú desees. Ten presente que Meta (propietaria de WhatsApp) tiene su propia política de precios. Estos se cobrarán directamente de tu cuenta de Meta. Tienes disponible hasta 1000 conversaciones al mes completamente gratis siempre y cuando sean iniciadas por tu cliente. En el caso de que tú inicies la conversación si implica un costo, pero este depende mucho de varios factores. Nos puedes contactar para que analicemos tu caso de uso y poder orientarte con una estimación real de acuerdo a tus necesidades. Por parte nuestra no te realizaremos ningún cobro adicional.",
      },
      {
        title: "¿Hay algún contrato o plazo mínimo que debo de cumplir?",
        description:
          "No hay contratos a largo plazos ni compromisos. Puedes cancelar en el momento que tú decidas.",
      },
      {
        title: "¿Tengo que pagar por actualizaciones?",
        description:
          "No, todas las actualizaciones que realicemos para mejorar serán siempre gratuitas.",
      },
      {
        title: "¿Quién es dueño de la información y los contactos?",
        description:
          "Tú eres el dueño total y absoluto de toda la información relacionada con tu número de WhatsApp. Nosotros nunca bajo ninguna circunstancia tenemos permitido usar ningún contenido relacionado con tu número.",
      },
      {
        title: "¿Facturan sus servicios?",
        description:
          "Si eres persona moral o física en México, te entregaremos tu factura del servicio mes a mes. Lo único que requerimos es tu Cédula de Identificación Fiscal para poder realizar la facturación y un correo electrónico para realizar el envío de tus documentos. Si perteneces a otro país te podemos proporcionar un recibo electrónico mes a mes. Todos nuestros precios ya incluyen IVA.",
      },
    ],
  },
};

export default faqwa