
const textProps = {
  color: "dark",
  type: "fixed",
  logoImage: require("assets/img/logo-blanco.png").default,
  logoText: "",
  logoLink: {
    to: "/landing-2",
  },
  socials: [
    {
      icon: "whatsapp",
      text: "Contactanos por WhatsApp",
      link: "https://wa.me/527292775173",
    },
    {
      icon: "facebook",
      text: "Compartenos en Facebook",
      link: "https://www.facebook.com/jiyu.mx",
    },
    {
      icon: "youtube",
      text: "Visita nuestro Canal de Youtube",
      link: "https://www.youtube.com/@jiyudesarrolloweb",
    },
    {
      icon: "linkedin",
      text: "Siguenos en LinkedIn",
      link: "https://www.linkedin.com/company/jiyu-mx",
    },
  ],
  leftLinks: [
    {
      to: "/",
      children: "Inicio",
    },
    {
      to: "/wacloudapi",
      children: "WhatsApp Business API",
    },
    {
      to: "/contact-us",
      children: "Contactanos",
    },
  ],
};
export default textProps;
