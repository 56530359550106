const pricingWa = {
  heading3: {
    color: "dark",
    subtitle: "Precios",
    title: "Ofrecemos la mejor tecnología a un excelente precio",
    description:
      "Permítenos mostrarte todo lo que podemos realizar por ti y tu Empresa",
    alignment: "center",
  },
  leftCard: {
    title: "Plan Jiyu - WhatsApp Cloud API",
    description: "Pago Mensual",
    price: "US$65",
    list: [
      { icon: "fab fa-whatsapp-square", text: "Para 1 linea de WhatsApp" },
      { icon: "fas fa-robot", text: "1 Chatbot" },
      { icon: "fas fa-comments", text: "Envios Masivos" },
      { icon: "fas fa-folder-open", text: "Campañas de Marketing" },
      { icon: "fas fa-file-code", text: "Integración con CRM" },
      { icon: "fas fa-user-check", text: "Gestión de Contactos" },
      { icon: "fas fa-file-export", text: "Manejo de Plantillas" },
      { icon: "fas fa-comment-dots", text: "Respuestas Rápidas" },
    ],
    button: {
      color: "red",
      children: "Iniciar Ahora",
      href:
        "https://wa.me/527292775173?text=Hola,%20Vi%20su%20página%20web%20y%20deseo%20hablar%20con%20alguien",
    },
    link: {
      href:
        "https://wa.me/527292775173?text=Hola,%20Vi%20su%20página%20web%20y%20deseo%20solicitar%20una%20demostración",
      children: "Solicita Una Prueba Demo Gratis y sin Compromiso.",
    },
  },
  rightCard: {
    title: "Valor Adicional Sin Costo Extra",
    description:
      "Además de una experiencia satisfactoria y personalizada, para México ofrecemos facturación mensual con IVA Incluido",
    table: [
      ["Configuración y Puesta en Producción", "Capacitamos a tu personal"],
      [
        "Soporte Técnico Personalizado Ilimitado",
        "1000 Conversaciones gratis al mes*",
      ],
    ],
    link: {
      href:
        "https://wa.me/527292775173?text=Hola,%20Vi%20su%20página%20web%20y%20deseo%20más%20información",
      children: "Contáctanos ahora",
    },
  },
};
export default pricingWa;