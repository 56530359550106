const textProps = {
  copyright: {
    children: "Jiyu",
    to: "/",
  },
  links: [
    {
      children: "Inicio",
      to: "/",
    },
    {
      children: "Contactanos",
      to: "/contact-us",
    },
    {
      children: "Aviso de Privacidad",
      to: "/aviso-de-privacidad",
    },
  ],
};
export default textProps;
