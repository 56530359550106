

const textProps = {
  image: require("assets/img/illustrations/bg5-1.svg").default,
  heading2: {
    color: "lightBlue",
    subtitle: "Contactanos",
    title: "Con gusto resolveremos cualquier duda que tengas",
    description:
      "Estamos para servirte",
    alignment: "center",
  },
  
  infoAreas: [
    /* {
      icon: "fas fa-map-marker-alt",
      color: "lightBlue",
      title: "Dirección",
      description: "Calle 25 #300, Prohogar. Azcapotzalco. CDMX. México",
    }, */
    {
      icon: "fas fa-envelope",
      color: "lightBlue",
      title: "Email",
      description: "jiyu.mx@gmail.com",
    },
    {
      icon: "fas fa-mobile-alt",
      color: "lightBlue",
      title: "Teléfono / WhatsApp",
      description: "+52 72 9277 5173",
    },
    {
      icon: "fas fa-user-circle",
      color: "lightBlue",
      title: "Contacto",
      description: "Iván Valdivia",
    },
  ],
};
export default textProps;
