import React, { useEffect } from "react";
import { useLocation, withRouter  } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

// sections

// components
import PreFooterLarge from "components/PreFooters/PreFooterLarge.js";
import FooterSmall from "components/Footers/Landing/FooterSmall.js";
/* import HeaderImageTitleCenterWA from "components/Headers/WaCloudApi/HeaderImageTitleCenterWA";*/
import HeaderVideoTitleWA from "components/Headers/WaCloudApi/HeaderVideoTitleWA"
import Features2 from "components/Sections/Misc/Features/Features2";
 /* import Pricing5 from "components/Sections/Misc/Pricing/Pricing5";
import pricing5 from "_texts/presentation/pricing/pricing5.js"; */
 import Pricing2 from "components/Sections/ECommerce/Pricing/Pricing2";
 import FAQ1 from "components/Sections/Misc/FAQ/FAQ1";
// texts for sections as props
import features2 from "_texts/misc/features/features2.js";
/* import headerimagetitlecenterwa from "_texts/wacloudapi/headers/headerimagetitlecenterwa.js";*/
import headervideotitlewa from "_texts/wacloudapi/headers/headervideotitlewa.js";
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import footersmall from "_texts/presentation/footers/footersmall.js";
import pricingWa from "_texts/presentation/pricing/precioWa";
import faqwa from "_texts/misc/faq/faqWa";

const Landing2 = () => {
    const location = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
  
  window.dataLayer.push({
    event: "pageview",
  });

    return (
      <>
        <CookieConsent
          location="bottom"
          buttonText="De Acuerdo"
          cookieName="JiyuCookie"
          style={{ background: "#2B373B" }}
          expires={150}
        >
          Esta Pagina Web utiliza cookies para ofrecerte una mejor experiencia.{" "}
          {/* <span style={{ fontSize: "10px" }}>Politica de Cookies</span> */}
        </CookieConsent>
        <HeaderVideoTitleWA {...headervideotitlewa} />
        {/* <HeaderImageTitleCenterWA {...headerimagetitlecenterwa} /> */}
        <div className="container mx-auto px-4">
          <div id="caracteristicas">
            <Features2 {...features2} />
          </div>

          {/* <div id="precio">
            <Pricing5 {...pricing5} />
          </div> */}
          <div id="precio">
            <Pricing2 {...pricingWa} />
          </div>
          <div id="faq">
            <FAQ1 {...faqwa} />
          </div>
        </div>
        <PreFooterLarge {...prefooterlarge} />
        <FooterSmall {...footersmall} />
      </>
    );
}

export default withRouter(Landing2);