const textProps = {
  title: "¡Gracias por visitarnos!",
  subtitle: "Siguenos en cualquiera de nuestras redes sociales.",
  socials: [
    {
      icon: "fab fa-whatsapp",
      href: "https://wa.me/7292775173",
      target: "_blank",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
    {
      icon: "fab fa-facebook",
      href: "https://www.facebook.com/jiyu.mx",
      target: "_blank",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
    {
      icon: "fab fa-youtube",
      href: "https://www.youtube.com/@jiyudesarrolloweb",
      target: "_blank",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
    {
      icon: "fab fa-linkedin",
      href: "https://www.linkedin.com/company/jiyu-mx",
      target: "_blank",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
  ],
};
export default textProps;
