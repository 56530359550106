import * as React from "react";

import { useDencrypt } from "use-dencrypt-effect";

const values = ["Unleash your Power!", "¡Libera tu poder!"];

const Textdencrypt = () => {
  const { result, dencrypt } = useDencrypt();

  React.useEffect(() => {
    let i = 0;

    const action = setInterval(() => {
      dencrypt(values[i]);

      i = i === values.length - 1 ? 0 : i + 1;
    }, 5000);

    return () => clearInterval(action);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{result}</div>;
};
export default Textdencrypt;
