const textProps = {
  video: require("assets/videos/particulas-low.mp4").default,
  image: require("assets/img/logo-blanco.png").default,
  title: "WhatsApp Business Cloud para tu negocio",
  buttonColor: "emerald",
  buttonText: "Conocenos",
  buttonIcon: "fas fa-laptop-code",
  skew: {
    color: "white",
  },
};
export default textProps;
