//import cardinfoiconleft from "_texts/misc/cards/cardinfoiconleft.js";

const textProps = {
  image: require("assets/img/illustrations/p31.svg").default,
  heading2badge: {
    badge: { color: "purple", children: "Caracteristicas" },
    title: "Libera todo el poder de WhatsApp Business",
    description:
      "Con nuestra integración personalizada olvídate de pasos técnicos engorrosos o de seguir manuales complejos. Relájate mientras nuestros expertos se encargan de configurar todo lo necesario para poner todo el potencial de WhatsApp Business Cloud al servicio de tu negocio.",
    alignment: "center",
    whiteContent: true,
  },
  cards: [
    {
      icon: "fas fa-users",
      color: "lightBlue",
      title: "Sistema Multi Agente",
      description:
        "Una plataforma única con bandeja de mensajes compartida y multi-agente, que permite interactuar en tiempo real con los clientes y gestionar todas las relaciones en un solo lugar.",
      quote:
        "- Todos los mensajes se reciben en un solo lugar y pueden ser atendidos rápidamente",
      /* link: {
        href: "#pablo",
        children: "Learn more",
      }, */
    },
    {
      icon: "fas fa-robot",
      color: "orange",
      title: "Chatbot de Autorrespuesta",
      description:
        "Tu número de WhatsApp será 24/7 los 365 días del año sin necesidad de que tu personal tenga que estar siempre conectado. Nunca más perderás una oportunidad de negocio.",
      /* link: {
        href: "#pablo",
        children: "Learn more",
      }, */
      quote:
        "- Un empleado 24/7 * 365 que no se cansa, no se queja y no te cuesta",
    },
    {
      icon: "fas fa-project-diagram",
      color: "red",
      title: "Conecta tu WhatsApp a otras plataformas",
      description:
        "Si tienes o piensas tener un CRM, te podemos ayudar a integrar ambas plataformas para que aproveches toda tu capacidad de negocio.",
      /* link: {
        href: "#pablo",
        children: "Learn more",
      }, */
      quote: "- Una plataforma que se adapta a tus necesidades",
    },
    {
      icon: "fas fa-user-check",
      color: "emerald",
      title: "Clasifica tus contactos",
      description:
        "Puedes agregar etiquetas a tus contactos para dar un seguimiento ordenado, personalizado y eficiente a cada uno de tus clientes. Tu cliente sentirá que lo conoces a la perfección y lograras proporcionar a tus clientes una experiencia personalizada de una manera muy sencilla y poderosa.",
      /* link: {
        href: "#pablo",
        children: "Learn more",
      }, */
      quote:
        "- Cada contacto clasificado de acuerdo a sus necesidades y preferencias.",
    },
    {
      icon: "fas fa-fast-forward",
      color: "indigo",
      title: "Simplifica todo con respuestas rápidas",
      description:
        "Puedes crear respuestas rapidas de acuerdo a tu uso para contestar de inmediato sin tener que escribir las respuestas rutinarias.",
      /* link: {
        href: "#pablo",
        children: "Learn more",
      }, */
      quote: "- Con un solo clic puedes contestar mensajes recurrentes.",
    },
    {
      icon: "fas fa-comments-dollar",
      color: "dark",
      title: "Difusión de Mensajes Masivos",
      description:
        "Con un solo clic, tendrás la capacidad de enviar mensajes personalizados y masivos a cientos o incluso miles de destinatarios. Además, si lo necesitas, podrás programar el envío para una fecha y hora específicas.",
      /* link: {
        href: "#pablo",
        children: "Learn more",
      }, */
      quote:
        "- Puedes lanzar promociones y que todos tus clientes se enteren con solo un clic",
    },
  ],
};

export default textProps;
