import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.min.css";
import "assets/styles/docs.css";
// Docs Routes - START - you can delete these when you no longer need our docs
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-57F8RN8",
};

TagManager.initialize(tagManagerArgs);

// Product Pages - STOP

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorkerRegistration.register();