import React, { useEffect } from "react";
import { useLocation, withRouter  } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

// sections
import Hero4 from "components/Sections/Presentation/Heroes/Hero4.js";
import Hero5 from "components/Sections/Presentation/Heroes/Hero5.js";

//import Pricing5 from "components/Sections/Misc/Pricing/Pricing5";

// components
import HeaderVideoTitle from "components/Headers/Presentation/HeaderVideoTitle.js";
import PreFooterLarge from "components/PreFooters/PreFooterLarge.js";
import FooterSmall from "components/Footers/Landing/FooterSmall.js";
// texts for sections as props

import hero4 from "_texts/presentation/heroes/hero4.js";
import hero5 from "_texts/presentation/heroes/hero5.js";
import nosotros from "_texts/presentation/heroes/nosotros.js";


//import pricing5 from "_texts/presentation/pricing/pricing5.js";
// texts for components as props
import headervideotitle from "_texts/presentation/headers/headervideotitle.js";
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import footersmall from "_texts/presentation/footers/footersmall.js";

const Landing2 = () => {
    const location = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
      <>
        <CookieConsent
          /* enableDeclineButton
        declineButtonText="Declinar (opcional)"
        onDecline={() => {
          resetCookieConsentValue();

        }} */
          location="bottom"
          buttonText="De Acuerdo"
          cookieName="JiyuCookie"
          style={{ background: "#2B373B" }}
          expires={150}
        >
          Esta Pagina Web utiliza cookies para ofrecerte una mejor experiencia.{" "}
          {/* <span style={{ fontSize: "10px" }}>Politica de Cookies</span> */}
        </CookieConsent>
        <HeaderVideoTitle {...headervideotitle} />
        <div className="container mx-auto px-4">
          <div id="nosotros">
            <Hero5 {...nosotros} />
          </div>
          <div id="tecnologia">
            <Hero4 {...hero4} />
          </div>

          <div id="filosofia">
            <Hero5 {...hero5} />
          </div>
          {/* <div id="planes">
            <Pricing5 {...pricing5} />
          </div> */}
        </div>
        <PreFooterLarge {...prefooterlarge} />
        <FooterSmall {...footersmall} />
      </>
    );
}

export default withRouter(Landing2);