import React from "react";

export default function Map() {
  
  return (
    <>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.4875920256213!2d-99.15888498461219!3d19.477647144284674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8f78a74e547%3A0x89b46cf00f396ec0!2sC.%2025%20300%2C%20Pro%20Hogar%2C%20Azcapotzalco%2C%2002600%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1632783540117!5m2!1ses-419!2smx" className="relative w-full h-500-px rounded-inherit" title="mapa" loading="lazy"></iframe>
    </>
  );
}
