const textProps = {
  heading2badge: {
    badge: { color: "pink", children: "Tus Aliados Estrategicos", round: true },
    title:
      "¿Necesitas una App, Plataforma o Página Web personalizada para tu negocio, pero los precios que has cotizado están fuera de tu presupuesto?",
    description:
      "En Jiyu te ofrecemos la opción adecuada para ti, las Progressive web apps (PWA). Para iniciar te podemos mencionar los siguientes beneficios",
    alignment: "center",
  },
  infoAreas: [
    {
      color: "lightBlue",
      icon: "fas fa-hand-holding-usd",
      title: "Precio accesible a cualquier presupuesto",
      description:
        "Nuestro modelo de negocio nos permite potenciar y hacer crecer el tuyo, pero no por ello no dejaremos de ofrecerte las mejores tecnologias. Crearemos soluciones digitales especificas a tus necesidades con la más alta Seguridad y Calidad. Si ganas tú, nosotros tambien ganamos.",
    },
    {
      color: "emerald",
      icon: "fas fa-atom",
      title: "Experiencias de Usuario Excepcionales",
      description:
        "Consigue crecer tu negocio creando una experiencia de usuario de primer nivel. Tu App será tuya, realizada a tu medida, utilizando tus fuentes y colores insitucionales, para crear una vinculación directa entre tu marca y tus clientes.",
    },
    {
      color: "orange",
      icon: "fas fa-globe-americas",
      title: "Infraestructura Poderosa y Escalable",
      description:
        "Con Firebase de Google, ofrecemos tambien la seguridad y tranquilidad para que tu App y tus datos esten siempre seguros, contando con la tecnologia de la mas alta vanguardia a nivel mundial. Olvidate de los cortes de servicios, ya que tendrás una disponibilidad del 99.99% garantizados por escrito.",
    },
  ],
};
export default textProps;
