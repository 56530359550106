
const textProps = {
  heading2badge: {
    badge: { color: "pink", children: "Nuestra Filosofia", round: true },
    title: "En japonés «Libertad» se llama Jiyu",
    description:
      "Una palabra que se compone de dos caracteres: el primero significa «Uno Mismo» y el segundo significa «Esencia». Para ser realmente felices debemos aspirar a ser quienes somos, y no quienes otros desean que seamos. Es tu idea, eres tu mismo.",
    alignment: "center",
  },
  infoAreas: [
    {
      color: "lightBlue",
      icon: "fas fa-cog",
      title: "Analisis de Requerimientos",
      description:
        "El primer paso será entrevistarnos para comprender a detalle tu necesidad. Realizaremos un documento de propuesta donde estableceremos junto a ti los objetivos, y te propondremos la mejor solución para alcanzar la meta.",
    },
    {
      color: "emerald",
      icon: "fas fa-atom",
      title: "Desarrollo",
      description:
        "Durante el proceso de Desarrollo estaremos en contacto permanente contigo para indicarte el avance del proyecto. El proceso de acompañamiento en esta etapa es crucial",
      
    },
    {
      color: "orange",
      icon: "fas fa-globe-europe",
      title: "Analisis de Rendimiento, Soporte y Mejora Continua",
      description:
        "Una vez terminado el desarrollo, estaremos contigo para brindarte la mejor atención. Todos nuestros planes incluyen Soporte Técnico, una fase de actualización y mejora continua, para que tu aplicación siempre este evolucionando.",
      
    },
  ],
};
export default textProps;
