import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";


// // error
import Error404 from "views/error/Error404.js";
// // presentation
import Landing2 from "views/presentation/Landing2.js";
import ContactUs from "views/presentation/ContactUs.js";
import NavbarLinks from "components/Navbars/NavbarLinks";
import navbarlinkslanding2 from "../src/_texts/presentation/navbars/navbarlinkslanding2.js";
import Landing1 from "views/presentation/Landing1";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import woman1 from "assets/img/faces/woman1.jpg";
import woman2 from "assets/img/faces/woman2.jpg";
import woman3 from "assets/img/faces/woman3.jpg";
import Landing3 from 'views/wacloudapi/Landing3.js';

const arrayWomen = [woman1, woman2, woman3];
const indiceAleatorio = Math.floor(Math.random() * 3);
const avatar = arrayWomen[indiceAleatorio];

function App() {

  return (
    <BrowserRouter>
      <FloatingWhatsApp
        phoneNumber={"+527292775173"}
        accountName={"Jiyu MX"}
        avatar={avatar}
        statusMessage={"Disponible"}
        chatMessage={
          "Hola! 🤝 \nBienvenido a Jiyu MX \n¿En qué podemos ayudarte?"
        }
        allowEsc
        allowClickAway
        notification
        notificationSound
        placeholder={"Escribe tu mensaje..."}
        onSubmit={()=> {window.dataLayer.push({'event': 'whatsapp'})}}
      />
      <Switch>
        {/* Product Pages - START */}
        {/* error */}
        <Route
          path="/error-404"
          exact
          render={() => (
            <React.Fragment>
              <NavbarLinks {...navbarlinkslanding2} />
              <div className="pt-17">
                <Error404 />
              </div>
            </React.Fragment>
          )}
        />
        <Route
          path="/index"
          exact
          render={() => (
            <React.Fragment>
              <NavbarLinks {...navbarlinkslanding2} />
              <div className="pt-17">
                <Landing2 />
              </div>
            </React.Fragment>
          )}
        />
        <Route
          path="/wacloudapi"
          exact
          render={() => (
            <React.Fragment>
              <NavbarLinks {...navbarlinkslanding2} />
              <div className="pt-17">
                <Landing3 />
              </div>
            </React.Fragment>
          )}
        />
        <Route
          path="/contact-us"
          exact
          render={() => (
            <React.Fragment>
              <NavbarLinks {...navbarlinkslanding2} />
              <div className="pt-17">
                <ContactUs />
              </div>
            </React.Fragment>
          )}
        />
        <Route
          path="/aviso-de-privacidad"
          exact
          render={() => (
            <React.Fragment>
              <NavbarLinks {...navbarlinkslanding2} />
              <div className="pt-17">
                <Landing1 />
              </div>
            </React.Fragment>
          )}
        />
        {/* Product Pages - STOP */}
        <Redirect from="/" to="/index" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;