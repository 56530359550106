
const textProps = {
  heading3: {
    color: "light",
    subtitle: "",
    title: "Aviso de Privacidad",
    description:
      "Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que Jorge Iván Valdivia Ramos, con domicilio en Calle 25 #300, Col. Prohogar, Azcapotzalco, CDMX 02600 es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.",
    alignment: "center",
    whiteContent: true,
  },
  heading4: {
    color: "light",
    subtitle: "",
    title: "",
    description:
      "Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.",
    alignment: "center",
    whiteContent: true,
  },
  heading5: {
    color: "light",
    subtitle: "",
    title: "",
    description:
      "Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:",
    alignment: "center",
    whiteContent: true,
  },
  heading6: {
    color: "light",
    subtitle: "",
    title: "",
    description:
      "Nombre completo, Nombre de la empresa, Teléfono fijo y/o celular, Correo electrónico, Página web. Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de estos o a revocar el consentimiento que para dicho fin nos haya otorgado.",
    alignment: "center",
    whiteContent: true,
  },
  heading7: {
    color: "light",
    subtitle: "",
    title: "",
    description:
      "Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su Art. 29 a Jorge Iván Valdivia Ramos, ubicado en Calle 25 #300, Col. Prohogar, Azcapotzalco, CDMX 02600, o bien, se comunique al teléfono (55) 72 9277 5173 o vía correo electrónico a jiyu.mx@gmail.com, el cual solicitamos confirme vía telefónica para garantizar su correcta recepción.",
    alignment: "center",
    whiteContent: true,
  },
  heading8: {
    color: "light",
    subtitle: "",
    title: "",
    description:
      "En caso de que no desee recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica: jiyu.mx@gmail.com",
    alignment: "center",
    whiteContent: true,
  },
  heading9: {
    color: "light",
    subtitle: "Importante",
    title: "",
    description:
      "Cualquier modificación a este Aviso de Privacidad podrá consultarlo en https://jiyui.mx. Fecha de última actualización: 28/08/2021",
    alignment: "center",
    whiteContent: true,
  },
  
};
export default textProps;
