import React from "react";
import { useLocation } from "react-router-dom";

// sections

import Hero3 from "components/Sections/Presentation/Heroes/Hero3.js";
// components

// texts for sections as props

import hero3 from "_texts/presentation/heroes/hero3.js";

// texts for components as props

import PreFooterLarge from "components/PreFooters/PreFooterLarge";
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import FooterSmall from "components/Footers/Landing/FooterSmall";
import footersmall from "_texts/presentation/footers/footersmall.js";



export default function Landing1() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <main>
        <div className="-mt-20">
          <Hero3 {...hero3} />
        </div>
      </main>
      
      <PreFooterLarge {...prefooterlarge} />
      <FooterSmall {...footersmall} />
    </>
  );
}
