import leftCardProps from "_texts/presentation/cards/cardinfoimage.js";
import rightCardInfoIconProps from "_texts/presentation/cards/cardinfofullcolor.js";

const textProps = {
  heading2badge: {
    badge: {
      color: "pink",
      children: "La mejor tecnologia",
    },
    title: "Ponemos a tu alcance la mejor infraestructura del mundo",
    description:
      "Tu aplicación se alojará en uno de los mejores Hostings del mercado, lo que significa Disponibilidad Total",
  },
  leftCardProps: {
    ...leftCardProps,
  },
  rightCardInfoIconProps: {
    ...rightCardInfoIconProps,
  },
  rightCardBlogImageProps: {
    color: "lightBlue",
    icon: "fas fa-palette",
    title: "Diseños Increibles",
    description:
    "Tu aplicacion tendra un diseño totalmente responsivo y personalizado. Tu lo imaginas, nosotros lo hacemos realidad.",
    gradient: false,
  },
  rightCardContactUsTitleProps: {
    color: "red",
    icon: "fas fa-running",
    title: "Desarrollo Ágil",
    description:
      "Utilizamos los metodos más agiles que existen para desarrollar tu Aplicación Web en el menor tiempo posible.",
    gradient: false,
  },
  rightCardInfoImageProps: {
    color: "emerald",
    icon: "fas fa-glasses",
    title: "Inovación Permanente",
    description:
      "Nos actualizamos todos los dias para ofrecerte la mejor tecnología del momento.",
    gradient: false,
  },
};
export default textProps;
