import React from "react";
import { useLocation } from "react-router-dom";

// sections
import ContactUs2 from "components/Sections/Presentation/ContactUs/ContactUs2.js";
// components
import PreFooterLarge from "components/PreFooters/PreFooterLarge.js";
import FooterSmall from "components/Footers/Landing/FooterSmall.js";
// texts for components as props
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import footersmall from "_texts/presentation/footers/footersmall.js";
import contactus2 from "_texts/presentation/contactus/contactus2.js";

export default function ContactUs() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      
      <div>
        <ContactUs2 {...contactus2} />
        <PreFooterLarge {...prefooterlarge} />
        <FooterSmall {...footersmall} />
      </div>
    </>
  );
}
