const textProps = {
  video: require("assets/videos/Lights - 26607.mp4").default,
  image: require("assets/img/logo-blanco.png").default,
  title: "Desarrollo de Aplicaciones Web",
  buttonColor: "emerald",
  buttonText: "Conocenos",
  buttonIcon: "fas fa-laptop-code",
  skew: {
    color: "white",
  },
};
export default textProps;
